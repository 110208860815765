import React from "react";
import { Page } from "../../components/page";
import styles from "./styles.module.scss"
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { ReactComponent as DownIcon } from "../../images/icons/double-arrow-bottom-icon.svg"

export const Home = () => {
  return (
    <React.Fragment>
      <Header />
      {/* <Page> */}
        <div className={styles.container}>
          <div className={`${styles.section} ${styles.firstSection}`}>
            <div className={styles.topVignette} />
            <div className={styles.bottomVignette} />
            <div className={styles.scrollDown}>
              <div className={styles.svgContainer}>
                <DownIcon />
              </div>
            </div>
          </div>
          <div className={`${styles.section} ${styles.secondSection}`}>
            <div className={styles.title}>
              Latest Release
            </div>
            <div className={styles.newestRelease}>
              <div>
                <iframe
                  className={styles.frame}
                  // src="https://www.youtube.com/embed?listType=playlist&list=UUXaiepwVOFrYat-fGzEqurw"
                  src="https://www.youtube.com/embed/bSjGbdpLVtE?si=4XvhQLtF8mCoik8o"
                  frameborder="0"
                  allowfullscreen
                />
                Youtube
              </div>
              <div>
                <iframe
                  className={styles.frame}
                  src="https://open.spotify.com/embed/album/0B8N7RWdpJY4d3sjpaKSbH?utm_source=generator&theme=0"
                  // width="100%"
                  // height="352"
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                />
                Spotify
              </div>
            </div>
            <Footer />
          </div>
        </div>
      {/* </Page> */}
    </React.Fragment>
  )
};
