import React, { useState } from "react"
import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import styles from "./styles.module.scss"
import MusicSheetImg from "../../images/musicsheet.jpg";

export const MusicSheet = ({}) => {
  const contents = [
    {
      link: "https://www.musicnotes.com/sheet-music/artist/moosicole",
      button: "Musicnotes",
      text: "Music Sheet for songs from \nAmerica, Australia and Europe",
    },
    {
      link: "https://www.mymusicsheet.com/moosicole",
      button: "MyMusicSheet",
      text: "Music Sheet for songs from Asia\n(K-Pop, K-Drama BGM & OST, etc.)",
    },
  ]

  const title = "Music Sheet"

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        <div className={styles.linkContainer}>
          { contents.map((content, i) => (
            <div className={styles.childContainer}>
              <div className={styles.text}>
                { content.text }
              </div>
              <a className={styles.button} href={content.link} target="_blank">
                { content.button }
              </a>
            </div>
          ))}
        </div>
        <div className={styles.background}>
          <div className={styles.vignette}>
          </div>
          <img src={MusicSheetImg} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}