import React, { useEffect, useState } from "react"
import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import styles from "./styles.module.scss"

export const Production = ({}) => {
  const maxWidth = 768
  const [width, setWidth] = useState(global.innerWidth)

  useEffect(() => {
    if (width >= maxWidth) {
      var iframe = document.getElementById("fb");
      if (iframe) {
        console.log(iframe.contentWindow.document.getElementsByClassName("_li"))
        var elmnt = iframe.contentWindow.document.getElementsByClassName("_li")[0];
        if (elmnt) {
          elmnt.style.height = "100%";
        }
      }
    }

    const onResize = () => {
      setWidth(global.innerWidth)
    }

    global.addEventListener("resize", onResize)

    return(() => {
      global.removeEventListener("resize", onResize)
    })
  }, [width])
  
  // https://open.spotify.com/embed/album/3j0tPrNzyrGyYwTNTDpzwZ?utm_source=generator&theme=0
  const contents = [
    {
      year: "2023",
      title: "Webtoon Left Hand (Original Soundtrack) Pt. 2",
      role: "Music Producer & Composer",
      src: "https://open.spotify.com/embed/album/0B8N7RWdpJY4d3sjpaKSbH?utm_source=generator&theme=0",
    },
    {
      year: "2023",
      title: "SLEEPLESS NIGHT (First Mini Album)",
      role: "Music Producer & Composer",
      src: "https://open.spotify.com/embed/album/0cApMzIe9SBJlUyqLz02Jd?utm_source=generator",
      
    },
    {
      year: "2022",
      title: "因為有你 (慈青30年紀念音樂專輯 )",
      role: "Music Composer, Lyricist & Singer",
      src: "https://open.spotify.com/embed/track/1nPtqQBjuFEwo7usgZgNsg?utm_source=generator&theme=0",
    },
    {
      year: "2021",
      title: "Webtoon Left Hand (Original Soundtrack) Pt. 1",
      role: "Music Producer & Composer",
      src: "https://open.spotify.com/embed/album/3j0tPrNzyrGyYwTNTDpzwZ?utm_source=generator",
    },
    {
      year: "2018",
      title: "大腦販賣機 (NTUA Radio and TV Department Graduation Exhibition) Promotional Film",
      role: "Music Composer & Arranger",
      src: "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FNTUARTV108%2Fvideos%2F2003935609874184%2F&show_text=0",
      type: "fb",
      supp: "Animation Design：沈莉臻",
    }
  ]

  const Embed = ({ src, type }) => {
    return (
      <div className={styles.embed}>
        <iframe
          id={type}
          className={`${styles.frame} ${type ? styles[type] : ""}`}
          src={src}
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </div>
    )
  }


  const Content = ({ year, title, role, supp, right }) => {
    return (
      <div className={`${styles.content} ${right ? styles.rightAlign : ''}`}>
        { year }
        <div className={styles.title}>
          { title }
        </div>
        <div>
          { role }
        </div>
        <div>
          { supp }
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        { contents.map((content, i) =>
          <div key={i} className={styles.contentContainer}>
            { i % 2 ? 
              <>
                <Content year={content.year} title={content.title} role={content.role} supp={content.supp} right={true}/>
                <Embed src={content.src} type={content.type} />
              </>
              :
              <>
                { width < maxWidth ?
                  <>
                    <Content year={content.year} title={content.title} role={content.role} right={false}/>
                    <Embed src={content.src} type={content.type ? content.type : ""} />
                  </>
                  :
                  <>
                    <Embed src={content.src} type={content.type ? content.type : ""} />
                    <Content year={content.year} title={content.title} role={content.role} right={false}/>
                  </>
                }
              </>
            }
            
          </div>
        )}
      </div>
      <Footer />
    </React.Fragment>
  )
}