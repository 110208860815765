import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Profile } from "./pages/profile";
import { Performance } from "./pages/performance";
import { Production } from "./pages/production";
import { MusicSheet } from "./pages/musicSheet";
import { Contact } from "./pages/contact";
import styles from "./styles.module.scss";

function App() {
  return (
    <div className={styles.main}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home/> } />
          <Route path="/home" element={ <Home/> } />
          <Route path="/profile" element={ <Profile/> } />
          {/* <Route path="/performance" element={ <Performance/> } /> */}
          <Route path="/production" element={ <Production/> } />
          <Route path="/music-sheet" element={ <MusicSheet/> } />
          <Route path="/contact" element={ <Contact/> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

{/*
firebase init
firebase deploy
*/}
