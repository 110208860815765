import React, { useState } from "react"
import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import styles from "./styles.module.scss"
import aboutImg from "../../images/about.jpg"

export const Profile = ({}) => {
  const profileText = [
    "Nicole Theodore is a talented pianist, singer-songwriter, and music composer from Indonesia. She began working as a piano and music theory teacher in her hometown at the young age of sixteen. In 2014, she enrolled in the Music Department of the National Taiwan University of Arts to pursue her passion for music. There, she majored in Piano Performance and minored in Music Composition, graduating Summa Cum Laude in 2018 with a Bachelor of Music degree.",
    "Following her graduation, Nicole began directing and planning various music concerts, ranging from Indonesian traditional music to modern music performances. She also created music content by publishing her music covers to her YouTube channel, \"moosicole\". As of January 2023, her channel has accumulated over 2 million views.",
    "In 2021, Nicole made her debut as the composer for the soundtrack of the Webtoon \"Left Hand\", and the first album was released on all streaming service platforms in 2023. She also began producing music sheets for TV Drama/Movie OSTs, BGMs, and pop songs through four major music sheet websites, namely MyMusicSheet, Musicnotes, Jellynote, and PianoKiwis. In recognition of her outstanding work, she was named the Signature Artist of Musicnotes starting from 2023."
  ]

  const years = [2013, 2014, 2015, 2017, 2018, 2019, 2020, 2021]
  const [chosenYearIdx, setChosenYearIdx] = useState(0);

  const performances = {
    "2021": [
      {
        title: "ERT Radio Station June Broadcast",
        role: "Online Piano Concert"
      }
    ],
    "2020": [
      {
        title: "Indonesian Cultural Performance",
        role: "Performance Director and Concert Planner",
        venue: "Chien Hsin University of Science and Technology"
      }
    ],
    "2019": [
      {
        title: "Taiwanese New Immigrants Cultural Performance",
        role: "Piano Solo - Indonesian Traditional Music Remix",
        venue: "慈濟板橋園區國議廳",
      }
    ],
    "2018": [
      {
        title: "Nicole Theodore Piano Recital",
        venue: "National Taiwan University of Arts",
      },
      {
        title: "揎鬧 Chinese Flute Recital",
        role: "Encore song - Piano Accompaniment",
        venue: "Gangshan Cultural Center"
      },
      {
        title: "Taichung City Government World Flower Expo and City Sports Subsidy Program Promotion",
        role: "Piano Accompaniment",
      }
    ]
  }

  const handleCircleOnClick = (i) => {
    setChosenYearIdx(i);
  }

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        <div className={`${styles.section} ${styles.firstSection}`}>
          {/* <div className={styles.topVignette} /> */}
          <div className={styles.textArea} style={{ zIndex: 2 }}>
            <div className={styles.title}>
              About Nicole
            </div>
            { profileText.map((text, i) =>
              <div key={i}>
                { text }
              </div>
            )}
          </div>
          {/* <img src={aboutImg}   style={{width: "100%", height: "auto", position: "absolute", bottom: 0, zIndex: 1 }} /> */}

        </div>
        <div className={`${styles.section} ${styles.secondSection}`}>
          <div className={styles.timeline}>
            { Object.keys(performances).map((year, id) => (
              <div className={styles.performance}>
                <div className={styles.year}>
                  { year }
                </div>
                { performances[year].map((performance, id) => (
                  <div className={styles.detail}>
                    <div className={styles.perfTitle}>
                      { performance.title }
                    </div>
                    { performance.role &&
                      <div className={styles.role}>
                        {`(${performance.role})`}
                      </div>
                    }
                    <div className={styles.venue}>
                      {performance.venue}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <Footer />

          {/* <div className={styles.line} />
          <div className={styles.timeline}>
            { years.map((year, i) =>
              <div className={styles.circleContainer} key={i}>
                <div className={`${styles.year} ${chosenYearIdx == i ? styles.chosenYear : ''}`}>
                  { year }
                </div>
                <div className={`${styles.circle} ${chosenYearIdx == i ? styles.chosenCircle : ''}`} onClick={() => handleCircleOnClick(i)}/>
              </div>
            )}
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.embed}>

            </div>
            <div className={styles.content}>
              
            </div>
          </div> */}
        </div>

      </div>
    </React.Fragment>
  )
}