import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as FacebookIcon } from "../../images/icons/facebook-icon.svg"
import { ReactComponent as InstagramIcon } from "../../images/icons/instagram-icon.svg"
import { ReactComponent as TikTokIcon } from "../../images/icons/tiktok-icon.svg"
import { ReactComponent as TwitterIcon } from "../../images/icons/twitter-icon.svg"
import { ReactComponent as YoutubeIcon } from "../../images/icons/youtube-icon.svg"
import { ReactComponent as EmailIcon } from "../../images/icons/email-icon.svg"

export const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contactContainer}>
        <a href="mailto:moosicole@gmail.com" target="_blank" className={styles.svgContainer}>
          <EmailIcon className={styles.special} />
        </a>
        <a href="https://www.youtube.com/channel/UCXaiepwVOFrYat-fGzEqurw" target="_blank" className={styles.svgContainer}>
          <YoutubeIcon />
        </a>
        <a href="https://www.tiktok.com/@moosicole" target="_blank" className={styles.svgContainer}>
          <TikTokIcon className={styles.special} />
        </a>
        <a href="https://www.instagram.com/moosicole/" target="_blank" className={styles.svgContainer}>
          <InstagramIcon />
        </a>
        <a href="https://twitter.com/moosicole" target="_blank" className={styles.svgContainer}>
          <TwitterIcon className={styles.special} />
        </a>
        <a href="https://www.facebook.com/moosicole/" target="_blank" className={styles.svgContainer}>
          <FacebookIcon />
        </a>
        </div>
      &copy; 2023 Nicole Theodore. All Rights Reserved.
    </div>
  )
};