import React, { useState } from "react"
import { Header } from "../../components/header"
import { Footer } from "../../components/footer"
import styles from "./styles.module.scss"
import { ReactComponent as FacebookIcon } from "../../images/icons/facebook-icon.svg"
import { ReactComponent as InstagramIcon } from "../../images/icons/instagram-icon.svg"
import { ReactComponent as TikTokIcon } from "../../images/icons/tiktok-icon.svg"
import { ReactComponent as TwitterIcon } from "../../images/icons/twitter-icon.svg"
import { ReactComponent as YoutubeIcon } from "../../images/icons/youtube-icon.svg"
import { ReactComponent as EmailIcon } from "../../images/icons/email-icon.svg"
import Logo from "../../images/white-logo.png"

export const Contact = ({}) => {
  const title = "Contact Us";
  const content = "Write us about any project or business inquiries!\n We'd love to work with you!";
  
  const contacts = [
    {
      // icon: FacebookIcon,
      link: "",
    },
    {
      // icon: FacebookIcon,
      link: "",
    },
    {
      // icon: FacebookIcon,
      link: "",
    },
    {
      // icon: FacebookIcon,
      link: "",
    }
  ];

  return (
    <React.Fragment>
      <Header />
      <div className={styles.container}>
        <div>
          <div className={styles.title}>
            { title }
          </div>
          <div className={styles.content}>
            { content }
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.logo}>
            <img src={Logo} />
          </div>
          <div className={styles.contactContainer}>
            <div className={styles.row}>
              <a href="mailto:moosicole@gmail.com" target="_blank" className={styles.svgContainer}>
                <EmailIcon className={styles.special} />
              </a>
              moosicole@gmail.com
            </div>
            <div className={styles.row}>
              <a href="https://www.youtube.com/channel/UCXaiepwVOFrYat-fGzEqurw" target="_blank" className={styles.svgContainer}>
                <YoutubeIcon />
              </a>
              Nicole Theodore Music
            </div>
            <div className={styles.row}>
              <a href="https://www.tiktok.com/@moosicole" target="_blank" className={styles.svgContainer}>
                <TikTokIcon className={styles.special} />
              </a>
              @moosicole
            </div>
            <div className={styles.row}>
              <a href="https://www.instagram.com/moosicole/" target="_blank" className={styles.svgContainer}>
                <InstagramIcon />
              </a>
              @moosicole
            </div>
            <div className={styles.row}>
              <a href="https://twitter.com/moosicole" target="_blank" className={styles.svgContainer}>
                <TwitterIcon className={styles.special} />
              </a>
              @moosicole
            </div>
            <div className={styles.row}>
              <a href="https://www.facebook.com/moosicole/" target="_blank" className={styles.svgContainer}>
                <FacebookIcon />
              </a>
              Nicole Theodore - moosicole
            </div>
          </div>
          
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}