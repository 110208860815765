import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

export const Header = () => {
  const maxWidth = 768
  const [width, setWidth] = useState(global.innerWidth)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const nav = {
    "Profile": "/profile",
    // "Performance": "/performance",
    "Production": "/production",
    "Music Sheet": "/music-sheet",
    "Contact": "/contact"
  }

  const showBurger = () => {
    return width < maxWidth
  }

  useEffect(() => {
    const onResize = () => {
      setWidth(global.innerWidth)
    }

    global.addEventListener("resize", onResize)

    return(() => {
      global.removeEventListener("resize", onResize)
    })
  }, [width])

  const handleBurgerOnClick = () => {
    setMenuIsOpen((prevState) => !prevState)
  }

  return (
    <div className={styles.magicFlex}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <a href="/home">moosicole</a>
        </div>
        { showBurger() ? 
          <svg className={styles.burger} onClick={handleBurgerOnClick}>
            <line x1="12%" y1="50%" x2="88%" y2="50%"
              className={`${styles.top} ${menuIsOpen ? styles.opened : ""}`} />
            <line x1="12%" y1="50%" x2="88%" y2="50%"
              className={`${styles.middle} ${menuIsOpen ? styles.opened : ""}`} />
            <line x1="12%" y1="50%" x2="88%" y2="50%"
              className={`${styles.bottom} ${menuIsOpen ? styles.opened : ""}`} />
          </svg>
          :
          <nav className={styles.navigation}>
            { Object.keys(nav).map((key, i) =>
              <div key={i}>
                <NavLink className={({ isActive }) => isActive ? styles.active : ''} to={nav[key]}>
                  { key }
                </NavLink>
              </div>
            )}
          </nav>
        }
      </div>
      { showBurger() && menuIsOpen &&
        <div className={styles.menu}>
          { Object.keys(nav).map((key, i) =>
            <div key={i}>
              <NavLink className={({ isActive }) => isActive ? styles.active : ''} to={nav[key]}>
                { key }
              </NavLink>
            </div>
          )}
        </div>
      }
    </div>
  )
};